<template>
  <div id="app">
    <nav id="nav">
      <ul>
        <li><strong>Leekwars Panel ({{ user != null ? user.displayName : '' }})</strong></li>
        <li><router-link to="/">Home</router-link></li>
        <li v-if="user != null"><router-link to="/manual">Manual Fights</router-link></li>
        <li v-if="user != null"><router-link to="/auto">Auto Fights</router-link></li>
        <li v-if="user == null"><router-link to="/about">About</router-link></li>
      </ul>
      <ul>
        <li v-if="user != null"><router-link to="/farmers">Farmers Account</router-link></li>
        <li v-if="user == null"><router-link to="/login">Login / Register</router-link></li>
        <li v-if="user != null"><router-link to="/logout">Logout</router-link></li>
      </ul>
    </nav>
    <main class="container">
      <router-view/>
    </main>
  </div>
</template>

<script>

import store from "@/store";

export default {
  data() {
    return {
      user: store.getters.getUser,
    }
  }
}

</script>

<style>
@import './assets/css/pico.min.css';


:root {
  --primary: #43a047;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
