import Vue from 'vue'
import App from './App.vue'
import store from '@/store.js';
import router from './router'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCG-hCCuDhFUYiU9A0uVz6Vvd948grTPEU",
  authDomain: "leekwarsv2.firebaseapp.com",
  projectId: "leekwarsv2",
  storageBucket: "leekwarsv2.appspot.com",
  messagingSenderId: "443760346698",
  appId: "1:443760346698:web:6270528340a96ceba2c8b3",
  measurementId: "G-2E8YNE810Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west3');
/*
import { connectFirestoreEmulator } from "firebase/firestore"
import { connectFunctionsEmulator } from "firebase/functions";
connectFirestoreEmulator(db, 'localhost', 8080);
connectFunctionsEmulator(functions, "localhost", 5001);
*/
auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('setUser', user);
  } else {
    store.dispatch('setUser', null);
  }
});

Vue.config.productionTip = false

Vue.firebase = Object;
Vue.firebase.app = app;
Vue.firebase.analytics = analytics;
Vue.firebase.auth = auth;
Vue.firebase.db = db;

Vue.firebase.functions = Object;
Vue.firebase.functions.base = functions;
Vue.firebase.functions.getFullFarmers = httpsCallable(functions, 'getFullFarmers');
Vue.firebase.functions.buyInMarket = httpsCallable(functions, 'buyInMarket');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
